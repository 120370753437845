import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Driver, ExpiredDriver } from '../__models/driver';
import { SynthesisDriver } from '../__models/synthesis-driver';
import { TimelineDriver } from '../__models/timeline-driver';
import { environment } from '../../environments/environment';
import { LeaApiService } from './lea-api.service';
import { RankingPeriod } from "../__models/ranking-period";

@Injectable()
export class DriversService {
  constructor(private _rest: LeaApiService) {}

  public getGroup(group: string): Observable<[Driver]> {
    return this._rest.get("admin/group/drivers/" + group);
  }

  getDriversActiveThisWeek(groupId: string): Observable<Driver[]> {
    return this._rest.get(`admin/groups/${groupId}/drivers?category=ACTIVE_THIS_WEEK`);
  }

  getDriversInactiveThisWeek(groupId: string): Observable<Driver[]> {
    return this._rest.get(`admin/groups/${groupId}/drivers?category=INACTIVE_THIS_WEEK`);
  }

  getDriversInactiveWithNoTripAtAll(groupId: string): Observable<Driver[]> {
    return this._rest.get(`admin/groups/${groupId}/drivers?category=NO_TRIP`);
  }

  public getTimeline(driverId) {
    return this._rest.get("admin/driver/timeline/" + driverId);
  }

  public getDriverGroupSynthesis(groupId, driverId) {
    return this._rest.get("admin/driver/synthesis/" + groupId + "/" + driverId);
  }

  public getDriver(driverId) {
    return this._rest.get("admin/drivers/" + driverId);
  }

  public getDriversRanking(
    period: RankingPeriod = RankingPeriod.PERMANENT,
    groupLabel: string = null
  ) {
    let groupLabelParam = groupLabel;
    if (groupLabelParam === null) {
      groupLabelParam = "";
    }
    return this._rest.get(
      "admin/achievements/ranking?period=" +
        period +
        "&groupLabel=" +
        groupLabelParam
    );
  }

  public deleteDriver(driverId) {
    let result = this._rest.deleteAndReturnHtml("admin/driver/" + driverId);
    return result;
  }

  public deleteFromGroup(driversIds, groupId) {
    return this._rest.post("admin/group/removedrivers", {
      driverIds: [driversIds],
      groupId: groupId,
    });
  }

  public updateDriverStatus(driverId, status) {
    let result = this._rest.put("admin/drivers/" + driverId + "/status", {
      status: status,
    });
    return result;
  }

  public updateDriversStatus(driverIds: Array<string>, status) {
    let result = this._rest.put("/admin/drivers/status", {
      status: status,
      driverIds: driverIds,
    });
    return result;
  }

  public cancelInvitation(driverId) {
    return this._rest.delete(`admin/drivers/invitations/${driverId}`);
  }

  public getExpiredDrivers(): Observable<ExpiredDriver[]> {
    return this._rest.get("admin/drivers/expired");
  }

  public deleteDrivers(ids: string[]): Observable<any> {
    return this._rest.delete(`admin/drivers`, { ids });
  }
}
